<template>
    <v-row>
      <v-col
        md="4"
        sm="6"
        cols="12"
        class="mt--30"
        v-for="(service, i) in serviceContent"
        :key="i"
      >
        <div class="standard-service">
          <div class="thumbnai">
            <img :src="service.src" alt="Corporate Images" />
          </div>
          <div class="content">
            <h3>
              <router-link to="/service-details">{{ service.title }}</router-link>
            </h3>
            <p class="service-desc">
              {{ service.desc }}
            </p>
            <router-link class="btn-transparent rn-btn-dark" :to="`/service-details#${service.title}`"
              ><span class="text">Leia Mais</span></router-link
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
    export default {
      data() {
        return {
          serviceContent: [
            {
              src: require("../assets/images/service/sm/promotor.jpeg"),
              title: "Promotor",
              desc: `Esses profissionais são especializados em apresentar produtos ou serviços...`,
            },
            {
              src: require("../assets/images/service/sm/recepcionista.jpg"),
              title: "Recepcionista",
              desc: `Recepcionistas são profissionais treinados que são responsáveis por dar as boas-vindas aos convidados e orientá-los durante o evento...`,
            },
            {
              src: require("../assets/images/service/sm/modelo.jpg"),
              title: "Modelo",
              desc: `Trabalhamos com uma equipe de profissionais experientes em recrutamento e seleção de modelos...`,
            },
            {
              src: require("../assets/images/service/sm/massage.png"),
              title: "Quick Massage",
              desc: `Imagine seus colaboradores desfrutando de momentos revitalizantes durante o expediente, permitindo uma pausa revigorante no meio da agitação...`,
            },
            {
              src: require("../assets/images/service/sm/influencer.jpg"),
              title: "Influencer",
              desc: `Além de aumentar a visibilidade da marca nas redes sociais, o influencer pode ajudar a aumentar o engajamento com o público-alvo...`,
            },
            {
              src: require("../assets/images/service/sm/garçon.jpg"),
              title: "Garçon",
              desc: `Garçons são treinados para fornecer serviços de alta qualidade, garantindo que seus convidados sejam bem cuidados e atendidos de forma atenciosa e profissional...`,
            },
            {
              src: require("../assets/images/service/sm/bartender.jpg"),
              title: "Bartender",
              desc: `Especialistas em coquetéis e bebidas, os bartenders podem criar bebidas personalizadas de acordo com as preferências...`,
            },
          ],
        };
      },
    };
  </script>
  <style lang="scss" scoped>
  .service-desc {
    margin-bottom: 5px !important;
  }
</style>