<template>
    <div>
      <v-navigation-drawer
        class="hidden-md-and-up header-one-page-nav-drawer"
        v-model="drawer"
        fixed
        width="320"
      >
        <v-list-item class="pa-5">
          <div class="logo">
            <img
              src="../assets/images/logo-sm.png"
              alt="brand-image"
            />
          </div>
          <v-spacer></v-spacer>
          <v-btn
            class="close-icon"
            icon
            @click="drawer = !drawer"
            v-html="iconSvg(closeIcon)"
          >
          </v-btn>
        </v-list-item>
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
          :offset="71"
        >
          <v-list>
            <v-list-item
              :ripple="false"
              v-for="item in items"
              :key="item.title"
              :to="item.to"
              link
              class="scrollactive-item"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- End mobile menu sidebar item list -->
          </v-list>
        </scrollactive>
      </v-navigation-drawer>
      <!-- End sidebar mobile menu -->
  
      <v-app-bar
        dark
        color="transparent"
        fixed
        elevate-on-scroll
        class="header-one-page-nav"
      >
        <router-link to="/" class="logo">
          <slot name="logo"></slot>
          <h3 class="site-name">Forever Jobs</h3>
        </router-link>
        <!-- End brand logo -->
        <v-spacer></v-spacer>
  
        <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
          <scrollactive
            active-class="v-btn--active"
            bezier-easing-value=".5,0,.35,1"
          >
            <v-btn
              v-for="item in items"
              :key="item.title"
              :to="item.to"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              >
              {{ item.title }}
            </v-btn>
          </scrollactive>
        </v-toolbar-items>
        <!-- End header menu item -->
        <a class="btn-default btn-border btn-opacity button-join" href="https://form.foreverjobsbrasil.com/" target="_blank">
          <span>Junte-se a nós</span>
        </a>
        <v-btn
          icon
          :ripple="false"
          class="ma-0 pa-0 hidden-md-and-up menu_icon"
          @click="drawer = !drawer"
          v-html="iconSvg(icon)"
        >
        </v-btn>
        <!-- End mobile menu icon -->
      </v-app-bar>
      <!-- End top header navbar -->
    </div>
  </template>
  
  <script>
    import feather from "feather-icons";

    export default {
      data: () => ({
        drawer: false,
        items: [
          { title: "Home", to: "/#home" },
          { title: "Serviços", to: "/#service" },
          { title: "Sobre nós", to: "/#about" },
          // { title: "Team", to: "#team" },
          { title: "Contato", to: "contact" },
        ],
        icon: "menu",
        closeIcon: "x",
      }),
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
  </script>
  <style>
  .site-name {
    color: white !important;
  }
  .button-join:hover {
    background-color: #5D2C88 !important;
    border-color: #5D2C88 !important;
  }

  @media (max-width: 623px) {
    .logo {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
    }
      .site-name {
        margin: 10px 0 0 0;
        font-size: 15px !important;
      }
    }
  /* .menu-item {
    color: white !important;
  }
  .menu-item:hover {
    color: #EA23FB !important;
  } */
  </style>
