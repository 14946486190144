import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import Home from '../views/welcome/Home.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home,
        meta: {
            title: 'Forever Jobs'
        },
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title: 'Forever Jobs - Sobre nós'
        },
        component: () =>
            import ('../views/About.vue')
    },
    {
        path: '/service-details',
        name: 'ServiceDetails',
        meta: {
            title: 'Forever Jobs - Serviço'
        },
        component: () =>
            import ('../views/service/ServiceDetails.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: 'Forever Jobs - Contato'
        },
        component: () =>
            import ('../views/Contact.vue')
    },
    {
        path: '/policy',
        name: 'Policy',
        meta: {
            title: 'Forever Jobs - Política de Privacidade'
        },
        component: () =>
            import ('../views/Policy.vue')
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: 'Forever Jobs - 404'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router