<template>
  <div class="wrapper">
    <v-container>
      <v-row class="justify-between align-center">
        <v-col lg="12">
          <div class="text-center inner">
            <div class="callto-action-area callto-action_area">
              <h3 class="heading-title white--text mb--30">
                Despertamos seu interesse? <br />
                Trabalhe conosco.
              </h3>
              <div class="contact-btn">
                <router-link class="btn-default" to="/contact"
                  >ENTRE EM CONTATO</router-link
                >
              </div>
            </div>
            <div class="justify-center logo">
              <router-link to="/"
                ><img
                  src="../assets/images/logo_sm.png"
                  alt="Logo images"
                  class="logo"
              /></router-link>
            </div>
            <ul class="justify-center social-share d-flex liststyle">
              <li v-for="(social, i) in socialList" :key="i">
                <a :href="social.url" target="_blank"
                  ><i class="fab" :class="social.icon"></i
                ></a>
              </li>
            </ul>
            <div class="text mt--20">
              <p>
                © {{ new Date().getFullYear() }}
                Forever Jobs Brasil. Todos os direitos reservados..
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        socialList: [
          {
            icon: "fa-facebook-f",
            url: "https://www.facebook.com/foreverjobsbrasil",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://www.linkedin.com/company/forever-jobs-brasil",
          },
          // {
          //   icon: "fa-twitter",
          //   url: "https://twitter.com/",
          // },
          {
            icon: "fab fa-instagram",
            url: "https://instagram.com/foreverjobsbrasil",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: black !important;

  .heading-title {
    margin-top: 10px !important;
  }
  .logo {
    margin: 15px 0 !important;
  }
}
  .v-application a {
    &.copyright_txt {
      color: var(--color-gray);
    }
  }
</style>
