<template>
    <div>
      <VueSlickCarousel
        v-bind="settings"
        class="slider-activation rn-slick-dot rn-slick-activation dot-light mb--0"
      >
        <div
          class="slide slide-style-2 fullscreen bg_image"
          data-black-overlay="5"
          v-for="(slider, i) in sliderContent"
          :key="i"
          :style="{ backgroundImage: 'url(' + slider.src + ')' }"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="inner" :class="slider.className">
                  <h1 class="heading-title">{{ slider.title }}</h1>
                  <p class="description">
                    {{ slider.desc }}
                  </p>
                  <div v-if="slider.btnExist" class="slide-btn">
                    <a class="btn-default btn-slider" href="https://form.foreverjobsbrasil.com/" target="_blank">
                      <span>{{ slider.btnText }}</span>
                    </a>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- End Single Slide  -->
      </VueSlickCarousel>
    </div>
  </template>
  
  <script>
    import VueSlickCarousel from "vue-slick-carousel";
  
    export default {
      components: { VueSlickCarousel },
      data() {
        return {
          sliderContent: [
            {
              className: "text-center",
              src: require("../assets/images/slider/team.jpeg"),
              title: "Seja parte de algo maior",
              desc: `Procurando uma carreira emocionante com infinitas possibilidades? Junte-se à nossa equipe hoje!`,
              btnExist: true,
              btnText: "Junte-se a nós",
            },
            {
              className: "text-center",
              src: require("../assets/images/slider/company.jpeg"),
              title: "Soluções para sua empresa",
              desc: 'Precisando de ajuda em seu próximo evento? Nós podemos ajudar.',
              btnExist: false,
              btnText: "Contact Us",
            },
          ],
          settings: {
            fade: true,
            dots: true,
            arrows: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            margin: 20,
            adaptiveHeight: true,
            autoplay: true,
            autoplaySpeed: 6000,
          },
        };
      },
    };
  </script>
  
  <style lang="scss">
  .slide {
    height: 950px !important;
  }
    .slick-slide {
      img {
        display: block;
        width: 100%;
      }
    }
    .btn-slider {
        background-color: #5D2C88 !important;
        border-color: #5D2C88 !important;
        p {
            color: white !important;
            font-size: 16px !important;
            padding: 0 !important;
        }
        &:hover {
            background-color: white !important;
            border-color: #5D2C88 !important;
            p {
                color: #5D2C88 !important;
            }
        }
    }
  </style>
  