<template>
    <div>
      <!-- Start Header Area -->
      <Header>
        <img slot="logo" :src="logo" />
      </Header>
      <!-- End Header Area -->
  
      <!-- Start Slider Area  -->
      <Slider id="home"/>
      <!-- End Slider Area  -->
  
      <!-- Start Service Area  -->
      <div
        class="service-area creative-service-wrapper ptb--120 bg_color--1"
        id="service"
      >
        <div class="container">
          <div class="mt_dec--30">
            <Service />
          </div>
        </div>
      </div>
      <!-- Start Service Area  -->
  
      <!-- Start About Area  -->
      <div class="about-area rm-about-style-2 ptb--120 bg_color--5" id="about">
        <div class="about-wrapper">
          <div class="container">
            <About />
          </div>
        </div>
      </div>
      <!-- Start About Area  -->
  
      <!-- Start team Area  -->
      <!-- <div class="rn-team-area ptb--120 bg_color--1" id="team">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-left section-title mb--25 mb_sm--0">
                <span class="subtitle">Our Experts</span>
                <h2 class="heading-title">Our Skilled Team</h2>
                <p class="description">
                  There are many variations of passages of Lorem Ipsum available,
                  <br />
                  but the majority have suffered alteration.
                </p>
              </div>
            </v-col>
          </v-row>
          <Team />
        </v-container>
      </div> -->
      <!-- End team Area  -->

      <!-- Start Brand Area -->
      <div class="rn-brand-area bg_color--1 ptb--120">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title text-center mb--30">
                <span class="subtitle">Principais clientes</span>
                <h3 class="heading-title">Marcas que trabalharam com a gente.</h3>
              </div>
            </v-col>
          </v-row>
          <Brand />
        </v-container>
      </div>
      <!-- End Brand Area -->
  
      <!-- Start Footer Area  -->
      <Footer />
      <!-- End Footer Area  -->
    </div>
  </template>
  
  <script>
    import Header from "../../components/Header";
    import About from "../../components/About";
    import Service from "../../components/Service";
    import Brand from "../../components/Brand";
    import Footer from "../../components/Footer";
    import Slider from "../../components/Slider";
  
    export default {
      name: "Home",
      components: {
        Header,
        About,
        Service,
        Brand,
        Footer,
        Slider,
      },
      data() {
        return {
          logo: require("../../assets/images/logo-sm.png"),
        };
      },
    };
  </script>
  