<template>
    <v-row class="mt--20">
      <v-col cols="12">
        <ul class="brand-style-2">
          <li v-for="(brand, i) in brandImages" :key="i">
            <img :src="brand.src" alt="Logo Images" />
          </li>
        </ul>
      </v-col>
    </v-row>
  </template>
  
  <script>
    export default {
      data() {
        return {
          brandImages: [
            {
              src: require("../assets/images/brand/Lenovo.png"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/bgs.png"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/BrasilPre.jpeg"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/mercado-livre.png"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/midea.png"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/vivo.png"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/brasil-connecting.jpeg"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/casa-criadores.jpeg"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/allied.jpeg"),
              url: "#",
            },
            {
              src: require("../assets/images/brand/ccxp.jpeg"),
              url: "#",
            },
          ],
        };
      },
    };
  </script>
  