<template>
    <v-row class="row--20 align-center">
      <v-col md="6" sm="12" cols="12">
        <div class="thumbnail">
          <img
            class="w-100"
            src="../assets/images/team/team.jpeg"
            alt="About Images"
          />
        </div>
      </v-col>
      <v-col md="6" sm="12" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <span class="subtitle">Sobre nós</span>
            <h3 class="heading-title">Forever jobs brasil</h3>
            <p class="description">
              Somos uma agência especializada em fornecer staff para eventos, contando com um corpo profissional composto por mais de quatro mil colaboradores distribuídos em todo o território brasileiro.
              <br />
              <br />
              Nossos profissionais são selecionados meticulosamente com base em suas habilidades e experiência, assegurando que estejam perfeitamente adequados para atender às suas exigências.
            </p>
          </div>
          <div class="purchase-btn mt--50">
            <router-link class="btn-transparent" to="/about"
              >POR QUE SOMOS DIFERENTES</router-link
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
    export default {};
  </script>
  
  <style lang="scss" scoped>
    .heading-title {
      color: black !important;
    }
  </style>
  